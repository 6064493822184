import { LOCALES } from '../locales';
const translation = {
  [LOCALES.SPANISH]: {
    companySaying: 'Conexión global. Mundo comercial.',
    welcomeBack: 'Hi, Welcome back',

    //fields
    firstName: 'Nombres',
    lastName: 'Apellidos',
    fullName: 'Nombre Completo',
    email: 'Correo Electrónico',
    telephone: 'Móvil',
    country: 'País',
    password: 'Clave',
    message: 'Mensaje',
    rubric: 'Rubro',

    // buttons
    loginBtn: 'Entrar',
    registerBtn: 'Registrarme',
    send: 'Enviar',
    all: 'Todos',

    // validations errors
    required: 'Requerido',
    invalidEmail: 'Ingrese un Email valido',
    max50caracteres: 'No puede pasar 50 caracteres',
    loginFailed: 'Correo Electrónico o clave incorrecto. Intente otra vez.',
    sendContactFailed: 'Hubo un error al intentar enviar su mensaje. Por favor, inténtelo de nuevo más tarde.',

    // mainNavbar
    myCurrentInquiry: 'Mi Cotización',
    myCurrentWishingList: 'Mi Pedido Deseado',
    myCurrentCartList: 'Mis Productos Deseados',
    aboutUs: '¿Quiénes somos?',
    blogPosts: 'Blogs & Novedades',
    products: 'Productos',
    contactUs: 'Contáctanos',
    requestQuote: 'Cotizar',

    // filter bar
    filters: 'Filtros',
    gender: 'Género',
    category: 'Categoría',
    colors: 'Colores',
    men: 'Hombre',
    // women: 'Mujer',
    women: 'Damas',
    kids: 'Niños',
    all: 'Todos',
    accesories: 'Accesorios',
    price: 'Precio',
    below: 'Menor',
    rating: 'Rating',
    up: 'Mas',
    clearAll: 'Limpiar Todo',

    sortBy: 'Ordenar por',
    featured: 'Destacado',
    newest: 'Nuevos',
    priceHighLow: 'Precio: Mayor-Menor',
    priceLowHigh: 'Precio: Menor-Mayor',





    // header
    haveAccountQuestion: '¿No tienes una cuenta?',
    haveAlreadyAccountQuestion: '¿Tiene una cuenta?',
    signupLink: 'Registrate',
    signinLink: 'Inicia Sesión',

    // login
    login: 'Iniciar Sesión',
    loginWelcome: '¡Hola, Te Damos La Bienvenida!',
    loginTitle: 'Portal GoComercio',
    loginDescription: 'Ingrese sus detalles',
    
    // register
    register: 'Registrar',
    registerFailed: 'Registro Fallido',
    registerEnviado: 'Registro Enviado',
    registerConfirmation: 'Confirmación de Registro',
    registerWelcome: 'Administre su requerimiento efectivamente',
    registerTitle: 'GoComercio Absolutamente Gratis',
    registerDescription: 'Registro. No necesita tarjeta de credito.',

    // account menu
    profile: 'Perfil',
    inquiries: 'Consultas',
    settings: 'Configuración',
    signout: 'Cerrar Sesión',

    // home
    home: 'Inicio',
    blog: 'Blog & Novedades',
    categories: 'Categorías',
    categoriesDescription: 'Navega a traves de todas las categorías',

    // inquiry
    productName: 'Producto',
    remarks: 'Notas',
    quantity: 'Cantidad',


    pageNotFound: '404 Página No Encontrada',
    myInquiries: '',
    myWishedOrder: 'Mis Consultas',

    shoppingCart: 'Mi Carrito',

    //Orders 
    orders: 'Pedidos'

      
  },
};
export default translation;
