import axios from 'axios';

let cache = new Map();

function cachingGet(get){

    return function cachedGet(url, fromServer){
        const key = url;

        if (cache.has(key) && !fromServer){
            return cache.get(key)
        }else{
            const request = get(...arguments);
            cache.set(key, request);
            return request;
        }
    }
}

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    // headers: {
    //     'Content-Type': 'application/json; charset=utf-8',
    //     'Accept': 'application/json',
    //     // 'Authorization': `Bearer ${AuthService.getToken()}`,
    //     'Authorization': `Bearer ${localStorage.getItem('token')}`,
    // }
});

axiosInstance.interceptors.request.use(config => {
    if (localStorage.token) {
        config.headers.Authorization = 'Bearer ' + localStorage.token
    }
    return config
},error =>{
    console.error("param error", 'fail');
    return Promise.reject(error);
});


axiosInstance.get = cachingGet(axiosInstance.get);

axiosInstance.clearCache = function(){
    cache = new Map();
}

export default axiosInstance;