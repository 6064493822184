import './App.scss';

import { useState, useEffect } from 'react';

// import {lazy, Suspense} from 'react';

// routes
import Router from './setup/routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
// import ScrollToTop from './app/components/ScrollToTop';
// import { BaseOptionChartStyle } from './app/components/chart/BaseOptionChart';

import { AuthProvider, RequireAuth } from './setup/auth/useAuth';

import { HelmetProvider } from 'react-helmet-async';
import { I18nProvider } from './i18n';

// services
import eventEmitter from './components/event-emitter';
import useAuth from './setup/auth/useAuth';

// ----------------------------------------------------------------------

export default function App() {
  const [locale, setLocale] = useState([process.env.REACT_APP_I18N]);

  eventEmitter.addListener('handleLocale', (value) => setLocale(value));
  let auth = useAuth();

//   <AuthProvider>
//   <HelmetProvider>
//     <I18nProvider locale={locale}>
//       <ThemeProvider>
//         {/* <ScrollToTop /> */}
//         {/* <BaseOptionChartStyle /> */}
//         {/* <Suspense fallback={<div>Loading...</div>}></Suspense> */}
//         {auth.loading ? <div>Loading</div> : <Router />}
//       </ThemeProvider>
//     </I18nProvider>
//   </HelmetProvider>
// </AuthProvider>

  return (
    <HelmetProvider>
        <I18nProvider locale={locale}>
          <ThemeProvider>
            <ScrollToTop />
            {/* <BaseOptionChartStyle /> */}
            {/* <Suspense fallback={<div>Loading...</div>}></Suspense> */}
            {auth.loading ? <div>Cargando...</div> : <Router />}
          </ThemeProvider>
        </I18nProvider>
      </HelmetProvider>
  );
}
