import http from '../../utils/http';
import { User } from './user';
/**
 * This represents some generic auth provider API, like Firebase.
 */

// const accountLoginForManagerUrl = 'AccAccount/LoginForManager';
const accountLoginUrl = 'AccAccount/Login';
const accountRegisterUrl = 'AccAccount/Register';

const authProvider = {
    isAuthenticated: false,
    async signin(username: string, password: string, callback: Function): Promise<boolean> {
        const { data } = await http.post<User>(accountLoginUrl, { username, password });

        if (data && data.jwtToken) {
            localStorage.setItem('currentUser', JSON.stringify(data));
            localStorage.setItem('token', data.jwtToken);
            authProvider.isAuthenticated = true;
        }

    
        //workaround
        callback(data);

        return authProvider.isAuthenticated;
    },
    signout(callback: VoidFunction) {
        localStorage.removeItem('currentUser');
        localStorage.removeItem('token');
        authProvider.isAuthenticated = false;
        // setTimeout(callback, 100);

        window.location.href = '/'; //workdaround, make
    },
    async signup(firstName: string, lastName: string, email: string): Promise<boolean> {
        const { data } = await http.post<User>(accountRegisterUrl, { firstName, lastName, email, userName: email });
        if (data && data.succeeded) {
            return true;
        }
        return false;
    },
};

export { authProvider };