import { lazy, Suspense } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

import { RequireAuth } from './auth/useAuth';

const lazyWithRetry = (componentImport) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem(
        'page-has-been-force-refreshed'
      ) || 'false'
    );

    try {
      const component = await componentImport();

      window.localStorage.setItem(
        'page-has-been-force-refreshed',
        'false'
      );

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        window.localStorage.setItem(
          'page-has-been-force-refreshed',
          'true'
        );
        return window.location.reload();
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw error;
    }
  });

const LogoOnlyLayout = lazyWithRetry(()=>import('../../app/layouts/LogoOnlyLayout'));
const MainLayout = lazyWithRetry(()=>import('../../app/layouts/main'));

const Login = lazyWithRetry(()=>import('../../app.account/login-view'));
const Register = lazyWithRetry(()=>import('../../app.account/register-view'));
const RegisterFail = lazyWithRetry(()=>import('../../app.account/register-fail'));
const RegisterSent = lazyWithRetry(()=> import('../../app.account/register-sent'));
const RegisterConfirmation = lazyWithRetry(()=> import('../../app.account/register-confirmation'));

const Home= lazyWithRetry(()=>import('../../portal.home'));
const ContactUs= lazyWithRetry(()=>import('../../portal.contact'));
const AboutUs= lazyWithRetry(()=>import('../../portal.about'));

const Blog= lazyWithRetry(()=>import('../../blog.main'));
const BlogPost= lazyWithRetry(()=>import('../../blog.post'));

const ProductList= lazyWithRetry(()=>import('../../product.main'));
const ProductDetail= lazyWithRetry(()=>import('../../product.main/detail-view'));

const ShoppingCartList= lazyWithRetry(()=>import('../../store.cart'));

const InquiryList= lazyWithRetry(()=>import('../../inquiry.main'));
const InquiryEdit= lazyWithRetry(()=>import('../../inquiry.main/edit-view'));
const InquiryDetail= lazyWithRetry(()=>import('../../inquiry.main/detail-view'));

const OrderList= lazyWithRetry(()=>import('../../order.main'));
const OrderDetail= lazyWithRetry(()=>import('../../order.main/detail-view'));

const UserProfile= lazyWithRetry(()=>import('../../usermanagement.main'));

const NotFound= lazyWithRetry(()=>import('../../app.404'));


// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/autenticacion', 
      element: <Suspense fallback={<div>Cargando...</div>}><LogoOnlyLayout /></Suspense>,
      children: [
        { path: 'inicia-sesion', element: <Suspense fallback={<div>Cargando...</div>}><Login /></Suspense> },
        { path: 'registrate', element: <Suspense fallback={<div>Cargando...</div>}><Register /></Suspense> },
        { path: 'registro-confirmacion-:uid-:code', element: <Suspense fallback={<div>Cargando...</div>}><RegisterConfirmation /></Suspense>  },
        { path: 'registro-enviado', element:  <Suspense fallback={<div>Cargando...</div>}><RegisterSent /></Suspense>  },
        { path: 'registro-fallido', element: <Suspense fallback={<div>Cargando...</div>}><RegisterFail /></Suspense>  },
      ],
    },
    {
      path: '/cuenta',
      element: <Suspense fallback={<div>Cargando...</div>}><MainLayout /></Suspense>,
      children: [
        { path: 'perfil', element: <RequireAuth><Suspense fallback={<div>Cargando...</div>}><UserProfile /></Suspense></RequireAuth> }
      ],
    },
    {
      path: '/carrito',
      element: <Suspense fallback={<div>Cargando...</div>}><MainLayout /></Suspense>,
      children: [
        { path: '', element: <Suspense fallback={<div>Cargando...</div>}><ShoppingCartList /></Suspense> },
      ],
    },
    // {
    //   path: '/cotizaciones',
    //   element: <Suspense fallback={<div>Cargando...</div>}><MainLayout /></Suspense>,
    //   children: [
    //     { path: '', element: <Suspense fallback={<div>Cargando...</div>}><InquiryList /></Suspense> },
    //     { path: 'consulta', element: <Suspense fallback={<div>Cargando...</div>}><InquiryEdit /></Suspense> }, 
    //     { path: 'consulta/:serialNumber', element: <RequireAuth><Suspense fallback={<div>Cargando...</div>}><InquiryDetail  /></Suspense></RequireAuth>  }, // As shopping cart behavior
    //   ],
    // },
    {
      path: '/pedidos',
      element: <Suspense fallback={<div>Cargando...</div>}><MainLayout /></Suspense>,
      children: [
        { path: '', element: <RequireAuth> <Suspense fallback={<div>Cargando...</div>}><OrderList /></Suspense></RequireAuth> },
        { path: ':serialNumber', element: <RequireAuth><Suspense fallback={<div>Cargando...</div>}><OrderDetail  /></Suspense></RequireAuth>  }, // As shopping cart behavior
      ],
    },
    {
      path: '/productos', //ALL //TODO: /category-slug/product-slug
      element: <Suspense fallback={<div>Cargando...</div>}><MainLayout /></Suspense>,
      children: [
        { path: '', element: <Suspense fallback={<div>Cargando...</div>}><ProductList /></Suspense> },
        { path: ':categorySlug', element: <Suspense fallback={<div>Cargando...</div>}><ProductList  /></Suspense>  }, // As shopping cart behavior
      ],
    },

    {
      path: '/blog',
      element: <Suspense fallback={<div>Cargando...</div>}><MainLayout /></Suspense>,
      children: [
        { path: '', element: <Suspense fallback={<div>Cargando...</div>}><Blog /></Suspense> },
        { path: ':slug', element: <Suspense fallback={<div>Cargando...</div>}><BlogPost /></Suspense> },
      ],
    },
    {
      path: '/:categorySlug',
      element: <Suspense fallback={<div>Cargando...</div>}><MainLayout /></Suspense>,
      children: [
        { path: ':productSlug', element: <Suspense fallback={<div>Cargando...</div>}><ProductDetail /></Suspense> },
      ],
    },

    {
      path: '/',
      element: <Suspense fallback={<div>Cargando...</div>}><MainLayout /></Suspense>,
      children: [
        { path: '', element: <Suspense fallback={<div>Cargando...</div>}><Home /></Suspense> },
        { path: 'contactanos', element: <Suspense fallback={<div>Cargando...</div>}><ContactUs /></Suspense> },
        { path: 'quienes-somos', element: <Suspense fallback={<div>Cargando...</div>}><AboutUs /></Suspense> },
        { path: '404', element: <Suspense fallback={<div>Cargando...</div>}><NotFound /> </Suspense>},
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    }
  ]);
}
